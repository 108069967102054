@use 'theme';
@use 'fonts';

.wrapper {
  & button.removeButton {
    padding: theme.spacing(0, 1);
    min-height: 0;
  }

  & .removeButtonContent {
    font-size: fonts.size('little');
    line-height: 18px;
  }
}
