@use 'theme';
@use 'fonts';
@use 'borders';
@use 'shadows';
@use 'transitions';
@use 'breakpoints';
@use 'display';

@mixin desktop() {
  .desktop &, &.desktop {
    @include breakpoints.up('small') {
      @content;
    }
  }
}

.inDesktop {
  display: none;

  @include desktop() {
    display: block;
  }
}

.inMobile {
  @include desktop() {
    display: none;
  }
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: theme.spacing(2, 2, 1);
  margin-bottom: theme.spacing(1);
  background-color: theme.palette('white');
  border-radius: borders.radius('md');
  width: 100%;
  transition: transitions.get('box-shadow');

  @include desktop() {
    margin-bottom: theme.spacing(0);
    padding: theme.spacing(1.5);
    border-bottom: borders.get();
  }
  
  &:hover {
    box-shadow: shadows.get('productCardHover');
    z-index: 1;
  }
}

.title {
  display: flex;
  align-items: center;
  min-height: 40px;

  @include desktop() {
    min-height: auto;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  color: theme.get('textColor');

  @include desktop() {
    flex-direction: row;
    align-items: center;
    gap: theme.spacing(2);
  }
}

.content {
  display: flex;
  justify-content: space-between;
}

.nameContent {
  @include desktop() {
    width: 169px;
  }
}

.priceContent {
  align-items: flex-end;

  @include desktop() {
    align-items: center;

    &.withAttributes {
      justify-content: end;
        width: 159px;

      @include breakpoints.up('medium') {
        width: 251px;
      }
    }
  }
}

.price {
  margin-right: theme.spacing(2);

  & > * {
    white-space: nowrap;
  }
}

.amount {
  @include desktop() {
    text-align: end;
  } 
}

.grossPrice {
  @extend .amount;
  @include desktop() {
    font-size: fonts.size('little');
  }
}

.quantity {
  font-size: fonts.size('little');
  color: theme.get('textColor');
  white-space: nowrap;
  @include desktop() {
    margin: theme.spacing(auto, 0);
  }
}

.ratingAndDeliveryWraper {
  display: flex;
  align-items: flex-start;
  gap: theme.spacing(1.5);
  margin-top: theme.spacing(1);

  @include desktop() {
    display: none;
  }
  
  &:not(:empty) {
    display: flex;
    margin-top: theme.spacing(0.5);
    height: 34px;

    @include desktop() {
      height: auto;
    }
  }
}

.producerPn {
  display: none;
  opacity: 0.4;

  @include desktop() {
    display: -webkit-box;
  }
}

.attributes {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin: theme.spacing(1, 0);
  opacity: 0.9;

  @include desktop {
    flex-direction: row;
    opacity: 1;
    margin: theme.spacing(0);
  }
}

.attribute {
  display: flex;
  align-items: center;

  @include desktop {
    width: var(--attributeWidth);
    padding: theme.spacing(0, 0.5);
  }
}

.attributeLabel {
  @extend .inMobile;
  margin-right: theme.spacing(1);

  @include desktop() {
    margin-right: theme.spacing(0);
  }
}

.deliveryLabel {
  margin-top: theme.spacing(-0.25);
  white-space: nowrap;
}

.longDeliveryLabel {
  display: none;

  @include breakpoints.up('medium') {
    display: block;
  }
}

.name {
  word-break: break-word;

  @include desktop() {
    // !important it's necessary because override inline style
    --ellipsisLines: 1 !important;
  }
}

.imageWrapper {
  display: flex;
  position: relative;
  min-width: 48px;
  min-height: 41px;
  margin-right: theme.spacing(2);

  @include desktop() {
    align-items: center;
  }
}

.image {
  & picture {
    display: flex;
    justify-content: center;
  }
}

.button {
  @include breakpoints.down('medium') {
    padding: theme.spacing(1.375);

    .buttonIcon {
      margin: theme.spacing(0);

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.buttonName {
  @include display.up('medium');
}
